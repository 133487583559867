<template>
  <b-modal
    id="modal-drug-store"
    ref="refModal"
    title="Hiệu thuốc"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="profile_picture"
              rules="required"
            >
              <image-view
                id="file-input-profile-picture"
                v-model="itemLocal.profile_picture"
                required
                label="Ảnh đại diện"
                align="center"
                :src="itemLocal.profile_picture"
                :width="100"
                :height="100"
                :instruction="null"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="store_picture"
              rules="required"
            >
              <image-view
                id="file-input-store-picture"
                v-model="itemLocal.store_picture"
                required
                label="Hình ảnh hiệu thuốc"
                align="center"
                :src="itemLocal.store_picture"
                :width="100"
                :height="100"
                :instruction="null"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Thông tin chung
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên hiệu thuốc <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="HomeIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="CH-01"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="opening_time"
              rules="required"
            >
              <select-time
                v-model="itemLocal.opening_time"
                required
                :default-value="itemLocal.opening_time"
                label="Giờ mở cửa"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="closing_time"
              rules="required"
            >
              <select-time
                v-model="itemLocal.closing_time"
                required
                :default-value="itemLocal.closing_time"
                label="Giờ đóng cửa"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="owner_name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Người quản lý <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="owner_name"
                  v-model="itemLocal.owner_name"
                  placeholder="Nguyễn Nam Phong"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Phone -->
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  SĐT <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  placeholder="0888666888"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Địa chỉ -->
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Thông tin địa chỉ
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="province"
              rules="required"
            >
              <select-province
                v-model="itemLocal.province"
                required
                :default-value="itemLocal.province"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="district"
              rules="required"
            >
              <select-district
                v-model="itemLocal.district"
                required
                :default-value="itemLocal.district"
                :province="itemLocal.province"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="ward"
              rules="required"
            >
              <select-ward
                v-model="itemLocal.ward"
                required
                :default-value="itemLocal.ward"
                :district="itemLocal.district"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="detail_address"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Địa chỉ chi tiết <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="detail_address"
                    v-model="itemLocal.detail_address"
                    placeholder="Số nhà 12, Khuất Duy Tiến"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="latitude"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Vĩ độ
                </template>
                <b-form-input
                  id="latitude"
                  v-model="itemLocal.latitude"
                  placeholder=""
                  :state="getValidationState(validationContext)"
                  trim
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="longitude"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Kinh độ
                </template>
                <b-form-input
                  id="longitude"
                  v-model="itemLocal.longitude"
                  placeholder=""
                  :state="getValidationState(validationContext)"
                  trim
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Active/Inactive -->
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.status"
                name="check-button"
                switch
                inline
              >
                Hoạt động
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BInputGroupPrepend,
  BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectProvince from '@/views/components/SelectProvince.vue';
import ImageView from '@/views/components/ImageView.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import SelectTime from '@/views/components/SelectTime.vue';
import useDrugStoreModal from './useDrugStoreModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,

    SelectProvince,
    ImageView,
    SelectDistrict,
    SelectWard,
    SelectTime,
    BFormCheckbox,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useDrugStoreModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
